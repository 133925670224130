import React from "react";

export default function PrivacyPolicy() {
  return (
    <div style={{ marginTop: "40px", padding: "0 20px", lineHeight: "1.4" }}>
      <h2>Privacy Policy</h2>
      <p style={{ textAlign: "left" }}>
        At Gameus (gameus.io), we take the privacy of our users very
        seriously. This Privacy Policy explains how we collect, use, and
        disclose personal information when you visit and use our website, mobile
        application, and services.
      </p>
      <p style={{ textAlign: "left" }}>
        Our team of experts works tirelessly to create fun and challenging
        quizzes that will test your knowledge and keep you on your toes. And
        with new quizzes added regularly, there's always something new to
        discover.
      </p>

      <p style={{ textAlign: "left" }}>
        Information Collection and Use: When you visit and use our website, we
        may collect certain information about you, including your IP address,
        browser type, and access times. This information is used to improve the
        performance and functionality of our website. We also use Google
        Analytics and Adsense to track user behavior and show personalized
        advertisements on our website.
      </p>
      <p style={{ textAlign: "left" }}>
        We may also collect personal information that you voluntarily provide to
        us, such as your name, email address, and other contact information,
        when you register for an account, participate in a survey, or contact
        customer support. We use this information to provide and improve our
        services, to communicate with you, and to personalize your experience on
        our website.
      </p>
      <p style={{ textAlign: "left" }}>
        Cookies and Similar Technologies: We use cookies and similar
        technologies to collect and store information when you use our website.
        Cookies are small data files that are placed on your device by a
        website. They help us to remember your preferences, to understand how
        you use our website, and to improve your experience. You can manage
        cookies through your browser settings.
      </p>
      <p style={{ textAlign: "left" }}>
      Disclosure of Personal Information: We may disclose your personal information to third parties in the following circumstances:
      </p>
      <p style={{ textAlign: "left" }}>
      To our service providers, who assist us in providing and improving our services. To comply with legal obligations, such as a court order or subpoena. To protect our rights, property, or safety, or the rights, property, or safety of others. Security: We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. However, no internet transmission is completely secure, and we cannot guarantee the security of your personal information.
      </p>
      <p style={{ textAlign: "left" }}>
      Changes to this Privacy Policy: We may update this Privacy Policy from time to time. We will notify you of any material changes by posting the new Privacy Policy on our website. You should review this Privacy Policy periodically to stay informed about how we are protecting your personal information.

      </p>

      <p style={{ textAlign: "left" }}>
      Contact Us: If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at spdjmedia@gmail.com
      </p>
      <p style={{ textAlign: "left" }}>Effective Date: January 26, 2023</p>
      <p style={{ textAlign: "left" }}>
      By using our website and services, you consent to the collection, use, and disclosure of personal information in accordance with this Privacy Policy.
      </p>
    </div>
  );
}

import React from 'react'
import "./ShortStatusButton.css"
export interface shortButtonInterface{
    step:string
}

export default function ShortStatusButton(props:shortButtonInterface) {
  return (
    <div className='short-status-button' >
        {props? props.step:"Step 1/3"}
    </div>
  )
}

import React from "react";

export default function AboutUs() {
  return (
    <div style={{ marginTop: "40px", padding: "0 20px", lineHeight: "1.4" }}>
      <h2>About Us</h2>
      <p style={{ textAlign: "left" }}>
      Welcome to Gameus Quiz, your one-stop destination for all things trivia! Our team of experts has curated a wide variety of quizzes on a range of subjects, from pop culture to history to science and more.
      </p>
      <p style={{ textAlign: "left" }}>
      At Gameus Quiz, we believe that learning should be fun and interactive. That's why we've designed our quizzes to be both challenging and enjoyable, with a mix of multiple choice and open-ended questions. Whether you're a trivia buff looking to test your knowledge or just looking for a fun way to pass the time, you'll find something to love at Gameus Quiz.
      </p>

      <p style={{ textAlign: "left" }}>
      We're constantly updating our selection of quizzes, so be sure to check back often to see what's new. And if you're a quiz creator yourself, we'd love to feature your work on our site. Simply reach out to us with your quiz idea and we'll take a look.
      </p>
      <p style={{textAlign:"left"}} >
      Thank you for choosing Gameus Quiz. Happy quizzing!
      </p>
    </div>
  );
}

import React, { useState } from "react";
import { categoryButtonProps } from "../../interface";
import "./CategoryButton.css";
export default function CategoryButton(props: categoryButtonProps) {
  const activeStyling = props.active
    ? { backgroundColor: "#7300E7",color:"white" }
    : {};
  return (
    <>
      <button
        style={activeStyling}
        className="category-button"
        onClick={props.onClickFunction}
      >
        {props.title}
      </button>
    </>
  );
}

import axios from "axios";

export async function checkIsShortOld() {
  const shortCookie = getCookie("__game_short");
  if (!shortCookie) {
    console.log("no short cookie");
    return false;
  }
  const tagOkay = (await axios.get("/inf_data/game/c/" + shortCookie)).data;
  if (tagOkay === "ok") {
    console.log("token valid");
    return true;
  } else {
    console.log("token expired");
    return false;
  }
  return false;
}


export function sanitizeURL(url: string) {
  if (url.indexOf("http://") === 0) {
    return url;
  }
  if (url.indexOf("https://") === 0) {
    return url;
  }
  return "http://" + url;
}
export function b64EncodeUnicode(e: string) {
  return btoa(
    encodeURIComponent(e).replace(/%([0-9A-F]{2})/g, function (e, n) {
      return String.fromCharCode(parseInt("0x" + n, 16));
    })
  );
}

export async function reportPage(str: string) {
  const shortType = getCookie("__game_short_type");
  if (shortType !== "0") {
    return true;
  }
  const reportLink =
    `https://us-central1-my-apps-server.cloudfunctions.net/sts?i=${str}&shortid=` +
    getCookie("__game_short_data");
  let adblockEnabled = false;
  try {
    const resp = await axios.get(
      "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
    );
    adblockEnabled = false;
    console.log("no adblock");
  } catch (error) {
    console.log("adblock detected");
    adblockEnabled = true;
  }

  if (!adblockEnabled) {
    await axios.get(reportLink);
    return true;
  }
  return true;
}

export async function waitCall(seconds: number) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(null);
    }, seconds * 1000);
  });
}

export function setCookie(
  name: string,
  value: string,
  time: number,
  unit: string
) {
  var expires = "";
  let expiry_time = 0;
  if (unit === "minutes") {
    expiry_time = time * 60 * 1000;
  }

  if (unit === "days") {
    expiry_time = time * 24 * 60 * 60 * 1000;
  }

  if (unit === "hours") {
    expiry_time = time * 60 * 60 * 1000;
  }

  var date = new Date();
  date.setTime(date.getTime() + expiry_time);
  expires = "; expires=" + date.toUTCString();
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

export function getCookie(name: string) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export async function getResponse(path: string) {
  try {
    const myData = (await axios.get(path)).data;
    if (typeof myData === "string") {
      return null;
    }
    return myData;
  } catch (error) {
    return null;
  }
}

export function randombetween(min: any, max: any) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

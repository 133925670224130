import React, { useState, useEffect, useRef } from "react";
import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CoinsContext, ShortContext } from "../../App";
import {
  getResponse,
  randombetween,
  reportPage,
  waitCall,
} from "../../utils";
import "./MainQuizPage.css";
import HeartImage from "../../assets/heart.png";
import AudienceImage from "../../assets/audience.svg";
import timerImage from "../../assets/timer.png";
import skipQuestionImage from "../../assets/skip-question.png";
import audienceImage from "../../assets/audience.png";
import useFreeIcon from "../../assets/usefree.png";
import coinImage from "../../assets/coin.png";
import cancelIcon from "../../assets/cancel.png";
import ShortStatusButton from "../ShortStatusButton";
import { getContinueTexts, getFinalLink } from "../../shortner_utils";

// const quizTime = 120;
let quizTime = 120;
export default function MainQuizPage() {
  const [isShort, setShort] = useContext(ShortContext);
  const [instruction, setInstruction] = useState<any>(null);

  useEffect(() => {
    setInstruction(getContinueTexts("MainQuiz"));
  }, [isShort]);

  const [coins, setCoins]: any = useContext(CoinsContext);
  const optionA: any = useRef(null);
  const optionB: any = useRef(null);
  const optionC: any = useRef(null);
  const optionD: any = useRef(null);

  const lifelineName: any = useRef(null);
  const lifelineDescription: any = useRef(null);
  const lifelineUseForFree: any = useRef(null);
  const lifelineUseCoins: any = useRef(null);

  const [lifelineUsingData, setLifelineUsingData] = useState<any>({});
  const [gotoLink, setGotoLink] = useState<any>(null);

  const timerSlider: any = useRef(null);

  const { state } = useLocation();
  const [allQuestions, setAllQuestions] = useState<any>(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(-0);
  const navigate = useNavigate();
  const [countdown, setCountDown] = useState<number>(quizTime);
  const [stopCountdown, setStopCountdown] = useState<boolean>(false);
  const [currentScore, setCurrentScore] = useState<any>(0);

  const [answerGiven, setAnswerGiven] = useState<boolean>(false);

  const [usedLifelines, setUsedLifelines] = useState([
    false,
    false,
    false,
    false,
  ]);

  useEffect(() => {
    async function count() {
      await waitCall(1);
      setCountDown(countdown - 1);
      const sliderWidth = 100 - (countdown / quizTime) * 100;
      timerSlider.current.style.width = sliderWidth + "%";
    }
    if (countdown > 1) {
      if (!stopCountdown) {
        if (!isShort) {
          count();
        }
      }
    } else {
      endQuiz();
    }
  }, [countdown]);

  function endQuiz() {
    // todo end quiz
    navigate("/finished/" + state.title, {
      state: { ...state, score: currentScore, gotoLink: gotoLink },
    });
  }
  useEffect(() => {
    if (!state) {
      navigate("/");
    } else {
      if (isShort) {
        const truncatedQuestions = state.questions.slice(0, 5);
        setAllQuestions(truncatedQuestions);
      } else {
        setAllQuestions(state.questions);
      }
    }
  }, []);

  useEffect(() => {
    setAnswerGiven(false);
  }, [currentQuestionIndex]);

  async function setAnswer(selectedOption: string, optionRef: any) {
    if (answerGiven) {
      return;
    }
    setAnswerGiven(true);
    const correctOption: string = allQuestions[currentQuestionIndex].correct;
    if (correctOption === selectedOption) {
      setCurrentScore(currentScore + 50);
    } else {
      setCurrentScore(currentScore - 25);
    }

    if (correctOption !== selectedOption) {
      optionRef.current.style.background = "var(--color-answer-wrong)";
      optionRef.current.classList.add("shake");
      await waitCall(1);
    }

    optionRef.current.classList.remove("shake");

    if (correctOption === "A") {
      optionA.current.style.background = "var(--color-answer-right)";
    }
    if (correctOption === "B") {
      optionB.current.style.background = "var(--color-answer-right)";
    }
    if (correctOption === "C") {
      optionC.current.style.background = "var(--color-answer-right)";
    }
    if (correctOption === "D") {
      optionD.current.style.background = "var(--color-answer-right)";
    }
    await waitCall(1);
    const array = [optionA, optionB, optionC, optionD];
    array.forEach((button) => {
      button.current.style.background = "";
    });

    if (currentQuestionIndex < allQuestions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      endQuiz();
    }
  }
  useEffect(() => {
    // reset button styles on question changes
    if (optionA.current) {
      const array = [optionA, optionB, optionC, optionD];
      for (let i = 0; i < array.length; i++) {
        const element = array[i];
        element.current.style.color = "white";
        element.current.querySelector(".audiencepoll-result").style.width =
          "0%";
      }
    }
  }, [currentQuestionIndex]);

  function useFiftyLifeline() {
    const currentQuestion = allQuestions[currentQuestionIndex];
    const correctOption = currentQuestion.correct;
    const options: any = [
      { A: optionA },
      { B: optionB },
      { C: optionC },
      { D: optionD },
    ].filter((obj) => {
      return Object.keys(obj)[0] !== correctOption;
    });

    for (let i = 0; i < 2; i++) {
      const key = Object.keys(options[i])[0];
      const button = options[i][key];
      button.current.style.color = "transparent";
    }
    setUsedLifelines((prevVal) => {
      prevVal[0] = true;
      return prevVal;
    });
    setLifelineUsingData(null);
  }

  function fiftyLifeLine(e: any) {
    if (usedLifelines[0]) {
      return;
    }
    const newData = {
      name: "50:50",
      description: `The 50:50 audience poll will help you to hide
    two incorrect answers from this question.`,
      onClick: useFiftyLifeline,
      image: "50:50",
    };
    setLifelineUsingData(newData);
  }

  function useAudiencePollLifeline() {
    const currentQuestion = allQuestions[currentQuestionIndex];
    const correctOption = currentQuestion.correct;

    const correctOptionPercentage = randombetween(50, 60);
    const percent1 = randombetween(30, 40);
    const percent2 = randombetween(20, 30);
    const percent3 = randombetween(10, 20);

    const options: any = [
      { A: optionA },
      { B: optionB },
      { C: optionC },
      { D: optionD },
    ].filter((obj: any) => {
      if (Object.keys(obj)[0] === correctOption) {
        const key = Object.keys(obj)[0];
        const button = obj[key];
        button.current.querySelector(".audiencepoll-result").style.width =
          correctOptionPercentage + "%";
        button.current.querySelector(
          ".audiencepoll-result"
        ).style.backgroundColor = "green";
      }
      return Object.keys(obj)[0] !== correctOption;
    });

    const array = [percent1, percent2, percent3];
    for (let i = 0; i < array.length; i++) {
      const key = Object.keys(options[i])[0];
      const button = options[i][key];
      button.current.querySelector(".audiencepoll-result").style.width =
        array[i] + "%";
      button.current.querySelector(
        ".audiencepoll-result"
      ).style.backgroundColor = "red";
    }
    setLifelineUsingData(null);
    setUsedLifelines((prevVal) => {
      prevVal[2] = true;
      return prevVal;
    });
  }

  function audiencePollLifeLine(e: any) {
    if (usedLifelines[2]) {
      return;
    }
    const newData = {
      name: "Audience Poll",
      description: `The audience poll will help you to identifying
      the correct answer from this question.`,
      onClick: useAudiencePollLifeline,
      image: audienceImage,
    };
    setLifelineUsingData(newData);
  }

  function useFreezeTimerLifeline() {
    setTimeout(() => {
      setStopCountdown(false);
      setCountDown(countdown);
    }, 10000);
    setStopCountdown(true);
    setLifelineUsingData(null);
    setUsedLifelines((prevVal) => {
      prevVal[1] = true;
      return prevVal;
    });
  }

  function freezeTimerLifeline(e: any) {
    if (usedLifelines[1]) {
      return;
    }

    const newData = {
      name: "Freeze Timer",
      description: `The Freez Timer poll will help you to freez
      the time for 30 seconds and give you more time.`,
      onClick: useFreezeTimerLifeline,
      image: timerImage,
    };
    setLifelineUsingData(newData);
  }

  function useFlipQuestionLifeline() {
    let shuffled = allQuestions
      .map((value: any) => ({ value, sort: Math.random() }))
      .sort((a: any, b: any) => a.sort - b.sort)
      .map(({ value }: any) => value);

    setAllQuestions(shuffled);
    setLifelineUsingData(null);
    setUsedLifelines((prevVal) => {
      prevVal[3] = true;
      return prevVal;
    });
  }

  function flipQuestionLifeline(e: any) {
    if (usedLifelines[3]) {
      return;
    }

    const newData = {
      name: "Skip Question",
      description: `The Skip Question lifeline will help you to skip the current question. `,
      onClick: useFlipQuestionLifeline,
      image: skipQuestionImage,
    };
    setLifelineUsingData(newData);
  }

  useEffect(() => {
    async function countClick() {
      await reportPage("fff");
      await finalLink();
    }
    setTimeout(() => {
      countClick();
    }, 2000);
  }, []);

  async function finalLink() {
    if (isShort) {
      const link = await getFinalLink();
      setGotoLink(link);
    }
  }

  return (
    <>
      {lifelineUsingData?.name ? (
        <div
          onClick={() => {
            setLifelineUsingData(null);
          }}
          className="lifeline-prompt-container"
        >
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="lifeline-prompt"
          >
            <div className="lifeline-name-tag">
              {lifelineUsingData.image !== "50:50" ? (
                <img src={lifelineUsingData.image} alt="" />
              ) : (
                lifelineUsingData.image
              )}
            </div>
            <div
              style={{
                fontSize: "var(--fs-800)",
                fontWeight: "700",
                marginTop: "20px",
              }}
            >
              {lifelineUsingData.name}
            </div>

            <div
              style={{
                fontSize: "var(--fs-500)",
                fontWeight: "400",
                margin: "0 40px",
                marginTop: "15px",
                color: "var(--color-title-2)",
              }}
              ref={lifelineDescription}
            >
              {lifelineUsingData.description}
            </div>
            <button
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "var(--purple-color)",
                width: "80%",
                marginTop: "20px",
                borderBottom: "4px solid white",
                columnGap: "7px",
                paddingTop: "12px",
                paddingBottom: "12px",
              }}
              onClick={lifelineUsingData.onClick}
            >
              <img
                style={{ width: "var(--fs-800)", height: "var(--fs-800)" }}
                src={useFreeIcon}
                alt=""
              />
              USE FOR FREE
            </button>
            <div
              style={{
                fontSize: "var(--fs-700)",
                fontWeight: "800",
                margin: "10px 0 ",
              }}
            >
              OR
            </div>

            <button
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "transparent",
                width: "80%",
                columnGap: "7px",
                border: "1px solid var(--color-border)",
                paddingTop: "15px",
                paddingBottom: "15px",
              }}
              onClick={() => {
                if (coins < 50) {
                  // todo show toast not enough coins
                } else {
                  setCoins(coins - 50);
                  lifelineUsingData.onClick();
                }
              }}
            >
              USE FOR{" "}
              <img
                style={{ width: "var(--fs-500)", height: "var(--fs-400)" }}
                src={coinImage}
                alt=""
              />{" "}
              50
            </button>
          </div>
        </div>
      ) : (
        ""
      )}

      {allQuestions && allQuestions[currentQuestionIndex] ? (
        allQuestions ? (
          <>
            <div
              style={{ marginTop: "30px", marginLeft: "30px" }}
              className="flex align-center"
            >
              <div
                style={{ width: "60px", height: "58px" }}
                className="thumbnail-container"
              >
                <img
                  style={{ width: "80%", height: "80%" }}
                  src={`/` + state.image}
                  alt=""
                />
              </div>
              {/* <img
                className="quiz-display-img-round"
                src={`/${state.image}`}
                alt=""
              /> */}
              <div style={{ textAlign: "left", marginLeft: "15px" }}>
                <div className="quiz-name-blue"> {state.title} </div>
                <div
                  style={{ fontSize: "var(--fs-400)" }}
                  className="quiz-winning-amount"
                >
                  Play & Win{" "}
                  <img
                    style={{ height: "var(--fs-200)" }}
                    src={coinImage}
                    alt=""
                  />{" "}
                  {state.winning}
                </div>
              </div>
            </div>

            {!isShort ? (
              <div style={{ margin: "0 20px" }} className="flex align-center">
                <div className="timer-slider-container">
                  <div ref={timerSlider} className="timer-slider"></div>
                </div>
                <div className="flex column">
                  <div className="timer-time font-500 bold">{countdown}</div>
                  <div
                    style={{ whiteSpace: "nowrap" }}
                    className="font-100 bold"
                  >
                    SEC LEFT
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            <div
              style={{ marginTop: "30px" }}
              className="question-ui flex align-center column"
            >
              <div className="font-300">
                Question {currentQuestionIndex + 1}/{allQuestions.length}
              </div>
              <div style={{ margin: "10px 0px" }} className="font-500 bold">
                {allQuestions[currentQuestionIndex].question}
              </div>

              {allQuestions[currentQuestionIndex].image ? (
                <img
                  style={{
                    margin: "10px auto",
                    height: "110px",
                    width: "auto",
                  }}
                  src={allQuestions[currentQuestionIndex].image}
                  alt=""
                />
              ) : (
                ""
              )}

              <div style={{ width: "100%" }} className="flex ">
                <button
                  className="answer-button font-400"
                  ref={optionA}
                  onClick={() => setAnswer("A", optionA)}
                >
                  <div className="audiencepoll-result"></div>
                  A. &nbsp;
                  {allQuestions[currentQuestionIndex].optionA}
                </button>
                <button
                  className="answer-button font-400"
                  ref={optionB}
                  onClick={() => setAnswer("B", optionB)}
                >
                  <div className="audiencepoll-result"></div>
                  B. &nbsp;
                  {allQuestions[currentQuestionIndex].optionB}
                </button>
              </div>
              <div style={{ width: "100%" }} className="flex ">
                <button
                  className="answer-button font-400"
                  ref={optionC}
                  onClick={() => setAnswer("C", optionC)}
                >
                  <div className="audiencepoll-result"></div>
                  C. &nbsp;
                  {allQuestions[currentQuestionIndex].optionC}
                </button>
                <button
                  className="answer-button font-400"
                  ref={optionD}
                  onClick={() => setAnswer("D", optionD)}
                >
                  <div className="audiencepoll-result"></div>
                  D. &nbsp;
                  {allQuestions[currentQuestionIndex].optionD}
                </button>
              </div>
            </div>
            <div style={{ marginTop: "10px" }}>
      {isShort ? <ShortStatusButton {...{ step: instruction }} /> : ""}
            </div>
            <div
              style={{ marginTop: "30px" }}
              className="current-score font-500 bold"
            >
              Your Score :{" "}
              <span style={{ color: "yellow", fontWeight: "900" }}>
                {currentScore}
              </span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px 10px 0px 10px",
                borderTop: "1px solid grey",
                position: "fixed",
                width: "100%",
                bottom: "var(--footer-height) ",
                left: "0",
                height: "140px",
                maxWidth: "var(--left-pane-width)",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  right: "0",
                  zIndex: "1000",
                  border: "1px solid grey",
                  borderRadius: "5000px",
                  left: "0",
                  margin: "auto",
                  width: "fit-content",
                  padding: "5px 25px",
                  display: "flex",
                  columnGap: "10px",
                  alignItems: "center",
                  backgroundColor: "var(--color-button-background-color)",
                  bottom: "90%",
                }}
              >
                <div className="font-300 bold">Use Lifelines</div>
              </div>

              <div className="main-box">
                <div
                  style={{ opacity: `${usedLifelines[0] ? "0.5" : "1"}` }}
                  className="skew-box b1"
                >
                  <div
                    onClick={fiftyLifeLine}
                    className="skew-box-container b1"
                  >
                    <div
                      style={{
                        color: "#FDE35E",
                        fontWeight: "700",
                        fontSize: "var(--fs-500)",
                        marginBottom: "7px",
                      }}
                      className="name-img"
                    >
                      50:50
                    </div>
                    <div
                      style={{
                        color: "--var(--color-title-2)",
                        fontSize: "var(--fs-200)",
                      }}
                    >
                      50:50
                    </div>
                    {usedLifelines[0] ? (
                      <img className="cancel-icon" src={cancelIcon}></img>
                    ) : (
                      <div className="use-tag">Use</div>
                    )}
                  </div>
                </div>
                <div
                  style={{ opacity: `${usedLifelines[1] ? "0.5" : "1"}` }}
                  className="skew-box b2"
                >
                  <div
                    onClick={freezeTimerLifeline}
                    className="skew-box-container b2"
                  >
                    <div className="name-img">
                      <img src={timerImage} alt="" />
                    </div>
                    <div
                      style={{
                        color: "--var(--color-title-2)",
                        fontSize: "var(--fs-200)",
                      }}
                    >
                      Freeze timer
                    </div>
                    {usedLifelines[1] ? (
                      <img className="cancel-icon" src={cancelIcon}></img>
                    ) : (
                      <div className="use-tag">Use</div>
                    )}
                  </div>
                </div>
                <div
                  style={{ opacity: `${usedLifelines[2] ? "0.5" : "1"}` }}
                  className="skew-box b3"
                >
                  <div
                    onClick={audiencePollLifeLine}
                    className="skew-box-container b3"
                  >
                    <div className="name-img">
                      <img src={audienceImage} alt="" />
                    </div>
                    <div
                      style={{
                        color: "--var(--color-title-2)",
                        fontSize: "var(--fs-200)",
                      }}
                    >
                      Audience Poll
                    </div>
                    {usedLifelines[2] ? (
                      <img className="cancel-icon" src={cancelIcon}></img>
                    ) : (
                      <div className="use-tag">Use</div>
                    )}
                  </div>
                </div>
                <div
                  style={{ opacity: `${usedLifelines[3] ? "0.5" : "1"}` }}
                  className="skew-box b4"
                >
                  <div
                    onClick={flipQuestionLifeline}
                    className="skew-box-container b4"
                  >
                    <div className="name-img">
                      <img src={skipQuestionImage} alt="" />
                    </div>
                    <div
                      style={{
                        color: "--var(--color-title-2)",
                        fontSize: "var(--fs-200)",
                      }}
                    >
                      Skip Question
                    </div>

                    {usedLifelines[3] ? (
                      <img className="cancel-icon" src={cancelIcon}></img>
                    ) : (
                      <div className="use-tag">Use</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )
      ) : (
        <></>
      )}
    </>
  );
}

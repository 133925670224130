import React from "react";
import { useNavigate } from "react-router-dom";
import "./Footer.css";
export default function Footer() {
  const navigate = useNavigate();
  return (
    <div className="footer">
      <a style={{width:"33%"}} href="/categories">
        <div
          style={{width:"100%"}}
          className="footer-button"
        >
          <div className="img">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="white"
              viewBox="0 0 24 24"
              stroke="white"
              strokeWidth="2"
              style={{ height: "20px", width: "20px" }}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
              ></path>
            </svg>
            <div className="font-400 bold-500">Category</div>
          </div>
        </div>
      </a>

      <a style={{width:"33%"}} href="/home">
        <div
          style={{width:"100%"}}
          className="footer-button"
        >
          <div className="img">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="white"
              style={{ width: "20px", height: "20px" }}
            >
              <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
            </svg>
            <div className="font-400 bold-500">Home</div>
          </div>
        </div>
      </a>
      <div
        onClick={() => {
          if (document.querySelector(".sidenav")) {
            const element: any = document.querySelector(".sidenav");
            element.style.width = "100%";
          }
        }}
        className="footer-button"
      >
        <div className="img">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="white"
            strokeWidth="2"
            style={{ width: "20px", height: "20px" }}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            ></path>
          </svg>
          <div className="font-400 bold-500">Profile</div>
        </div>
      </div>
    </div>
  );
}

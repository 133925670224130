import React, { useContext } from "react";
import "./Profile.css";
import profileImage from "../../assets/profile.png";
import { CoinsContext } from "../../App";
import { QuizPlayedContext } from "../../App";

export default function Profile() {
  const [coins, setCoins]: any = useContext(CoinsContext);
  const [playedQuiz, setPlayedQuiz]: any = useContext(QuizPlayedContext);
  return (
    <>
      <div
        style={{ width: "100%", columnGap: "20px", marginTop: "40px" }}
        className="flex align-center justify-center"
      >
        <img className="profile-image" src={profileImage} alt="" />
        <div className="flex column ">
          <h2>USER X</h2>
          <div className="font-300 bold ">Number not updated</div>
          <div className="font-300 bold">Email not updated</div>
        </div>
      </div>

      <div
        style={{ width: "100%", columnGap: "40px", marginTop: "30px" }}
        className="flex align-center justify-center"
      >
        <div className="general-button orange-background">
          <div className="font-300 bold">Coins</div>
          <div className="font-500 bold">{coins}</div>
        </div>

        <div className="general-button">
          <div className="font-300 bold">Quiz Played</div>
          <div className="font-500 bold">{playedQuiz}</div>
        </div>
      </div>

      <div
        style={{ marginTop: "30px" }}
        className="flex align-center justify-center"
      >
        <div
          style={{
            padding: "10px 30px",
            backgroundColor: "blue",
            cursor: "pointer",
          }}
          className="general-button"
        >
          <div style={{ fontSize: "18px" }} className=" bold">
            Join Now
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useRef, useState, useEffect, useContext } from "react";
import InnerHTML from "dangerously-set-html-content"
import axios from "axios";
import CategoryButton from "../CategoryButton";
import "./Home.css";
import QuizListItem from "../QuizListItem";
import { CategoryTab, QuizListItemInterface } from "../../interface";
import { getResponse, reportPage, setCookie } from "../../utils";
import { Link } from "react-router-dom";
import ShortStatusButton from "../ShortStatusButton";
import { ShortContext } from "../../App";
import { getContinueTexts } from "../../shortner_utils";
export default function Home() {
  window.googletag = window.googletag || { cmd: [] };
  let googletag = window.googletag;
  function loadInterstitialAd() {
    window.googletag = window.googletag || { cmd: [] };
    var interstitialSlot;
    googletag.cmd.push(function () {
      interstitialSlot = googletag.defineOutOfPageSlot(
        "/22815341665/Bakenor_Interstitial",
        googletag.enums.OutOfPageFormat.INTERSTITIAL
      );
      if (interstitialSlot) {
        interstitialSlot.addService(googletag.pubads());
      }
      googletag.pubads().set("page_url", "https://bakenor.com/");
      googletag.enableServices();
      googletag.display(interstitialSlot);
    });
  }
  function loadAdHead() {
    window.googletag = window.googletag || { cmd: [] };
    googletag.cmd.push(function () {
      var mapping1 = googletag
        .sizeMapping()
        .addSize(
          [479, 0],
          [
            [300, 250],
            [250, 250],
            [200, 200],
            [336, 280],
          ]
        )
        .addSize([0, 0], [[300, 250]])
        .build();
      googletag
        .defineSlot(
          "/22815341665/Bakenor_topbanner",
          [[300, 250]],
          "div-gpt-ad-6577565-3"
        )
        .defineSizeMapping(mapping1)
        .addService(googletag.pubads());
      googletag.pubads().enableSingleRequest();
      googletag.pubads().set("page_url", "https://bakenor.com/");
      googletag.enableServices();
    });
  }
  function loadAdBody() {
    googletag.cmd.push(function () {
      googletag.display("div-gpt-ad-6577565-3");
    });
  }
  useEffect(() => {
    try {
      console.log("loading banner");
      // loadAdHead();
      // loadAdBody();
      // setTimeout(() => {
      //   loadInterstitialAd();
      // }, 1000);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const sliderContainer: any = useRef(null);
  const [tabsList, setTabsList] = useState<Array<CategoryTab> | null>(null); // changing only once
  const [quizzesList, setQuizzesList] =
    useState<Array<Array<QuizListItemInterface> | null> | null>(null); // changing only once
  const [currentTab, setCurrentTab]: any = useState(null); // index of active tab

  useEffect(() => {
    // setting cookie for navigations
    if (quizzesList) {
      const cookieData = quizzesList![currentTab];
      setCookie(
        "__game_quizInfo",
        JSON.stringify(cookieData ? cookieData : ""),
        1,
        "days"
      );
    }
  }, [currentTab]);
  useEffect(() => {
    const executeFunction = async () => {
      if (tabsList) {
        // fetching list of quizzes for each category
        if (!quizzesList) {
          const final_array: Array<Array<QuizListItemInterface>> = [];
          for (let i = 0; i < tabsList.length; i++) {
            const tab = tabsList[i];
            const urlpath = tab.filepath;
            const quizCategory = await getResponse(urlpath);
            if (quizCategory) {
              const quizzes = quizCategory.quizzes;
              const category = quizCategory.category;
              const quizList: Array<QuizListItemInterface> = [];
              for (let j = 0; j < quizzes.length; j++) {
                quizList.push({ ...quizzes[j] });
              }
              let shuffled = quizList
                .map((value: any) => ({ value, sort: Math.random() }))
                .sort((a: any, b: any) => a.sort - b.sort)
                .map(({ value }: any) => value);
              final_array.push(shuffled);
            }
          }

          setCurrentTab(0);
          setQuizzesList(final_array);
        }
      }
    };
    executeFunction();
  }, [tabsList]); // runs when data is available

  useEffect(() => {
    // get categories list for populating slider tabs
    const executeFunction = async () => {
      const data = await getResponse("/categories.json");
      if (data) {
        setTabsList(data);
      }
    };
    executeFunction();
  }, []);
  const [isShort, setShort] = useContext(ShortContext);
  const [instruction, setInstruction] = useState<any>(null);

  useEffect(() => {
    setInstruction(getContinueTexts("Home"));
    async function countClick() {
      console.log(isShort + "anupam");
      if (isShort) {
        await reportPage("ff");
      }
    }
    countClick();
  }, [isShort]);

  useEffect(() => {
    if (isShort) {
      reportPage("ff");
    }
  }, []);

  return (
    <div className="home">
      <div className="live-adunit">
        <div id="div-gpt-ad-6577565-3"></div>
        <InnerHTML html={`

<script type="module" src="https://securepubads.shareusads.com/scripts/tag/js/sgpt.js" ></script>

<div id="shareusadx-3948125447778714-4323306444">
 <script>
     window._shareustag = window._shareustag || {cmd:[]};
    _shareustag.cmd.push(function() {
      var slot1 = _shareustag.defineSlot('/shareusadx/3948125447778714/4323306444/Main Adunit', [[300,250],[300,50],[300,75],[300,100]],'shareusadx-3948125447778714-4323306444');
    });
  </script>
</div>


          `} />
      </div>

      <div style={{ marginTop: "15px" }}>
      {isShort ? <ShortStatusButton {...{ step: instruction }} /> : ""}
      </div>
      <div className="slider-component">
        {/* <button
          className="scroll-button"
          onClick={() => {
            sliderContainer.current.scrollBy(-300, 0);
          }}
        >
          {"<"}
        </button> */}
        <div className="slider-items" ref={sliderContainer}>
          {tabsList && currentTab !== null
            ? tabsList.map((category: any, index: number) => {
                return (
                  <CategoryButton
                    key={index.toString()}
                    active={tabsList[index].name === tabsList[currentTab].name}
                    title={category.name}
                    onClickFunction={() => {
                      setCurrentTab(index);
                    }}
                  />
                );
              })
            : "Loading"}
        </div>
        {/* <button
          className="scroll-button"
          onClick={() => {
            sliderContainer.current.scrollBy(300, 0);
          }}
        >
          {">"}
        </button> */}
      </div>

      {quizzesList?.[currentTab] ? (
        <div className="quiz-list">
          {quizzesList![currentTab]!.map((quiz, index) => {
            return (
              <a
                style={{ width: "100%" }}
                href={`/info/` + quiz.title + `?index=` + index}
              >
                <QuizListItem {...{ ...quiz }} />
              </a>
            );
          })}
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

import React, { DOMElement, useContext, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CoinsContext, LoginContext } from "../../App";
import "./NavBar.css";
// import siteLogo from "../../assets/bakenor-logo.png";
import siteLogo from "../../assets/gameus-logo.png";
import burgerMenu from "../../assets/burger-menu.png";
import coin from "../../assets/coin.png";
import arrowRight from "../../assets/left-arrow.png";
import user from "../../assets/user.png";
import categoryIcon from "../../assets/category-icon.png";
import alertIcon from "../../assets/alert.png";
import treasureIcon from "../../assets/treasure.png";
import user2Icon from "../../assets/user-2.png";
import emailIcon from "../../assets/email.png";
import privacyIcon from "../../assets/privacypolicy.png";
import useFreeIcon from "../../assets/usefree.png";
import { authenticateWithGoogle, logoutFromGoogle } from "../../firebase";

export default function NavBar() {
  const [coins, setCoins]: any = useContext(CoinsContext);
  const [loggedIn, setLoggedIn]: any = useContext(LoginContext);
  const navigate = useNavigate();

  const [openOverlay, setOpenOverlay]: any = useState(false);
  const sideNavRef = useRef<any>(null);
  function closeSidebar() {
    sideNavRef.current.style.width = "0%";
  }
  return (
    <>
      {openOverlay ? (
        <div
          onClick={() => {
            setOpenOverlay(false);
          }}
          className="lifeline-prompt-container"
          style={{ position: "fixed", maxWidth: "var(--left-pane-width)" }}
        >
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="lifeline-prompt"
          >
            <div className="lifeline-name-tag">
              <img src={treasureIcon} alt="" />
            </div>
            <div
              style={{
                fontSize: "var(--fs-800)",
                fontWeight: "700",
                marginTop: "20px",
              }}
            >
              {"Win 50 Coins Free"}
            </div>

            <div
              style={{
                fontSize: "var(--fs-500)",
                fontWeight: "400",
                margin: "0 40px",
                marginTop: "15px",
                color: "var(--color-title-2)",
              }}
            >
              {"Redeem your 50 bonus coins "}
            </div>
            <button
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "var(--purple-color)",
                width: "80%",
                marginTop: "20px",
                borderBottom: "4px solid white",
                columnGap: "7px",
                paddingTop: "12px",
                paddingBottom: "12px",
              }}
              onClick={() => {
                setCoins(coins + 50);
                setOpenOverlay(false);
              }}
            >
              <img
                style={{ width: "var(--fs-800)", height: "var(--fs-800)" }}
                src={useFreeIcon}
                alt=""
              />
              USE FOR FREE
            </button>
            <div
              style={{
                fontSize: "var(--fs-700)",
                fontWeight: "800",
                margin: "10px 0 ",
              }}
            >
              OR
            </div>

            <button
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "transparent",
                width: "80%",
                columnGap: "7px",
                border: "1px solid var(--color-border)",
                paddingTop: "15px",
                paddingBottom: "15px",
              }}
              onClick={() => {
                setOpenOverlay(false);
              }}
            >
              Close
            </button>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="navbar">
        <div
          style={{ width: "100%", height: "100%" }}
          className="flex align-center space-between"
        >
          <div
            style={{ columnGap: "8px", width: "100%", height: "100%" }}
            className="flex align-center"
          >
            <img
              onClick={() => {
                console.log("called click");
                sideNavRef.current.style.width = "100%";
                // sideNavRef.current.style.padding = "10px";
              }}
              style={{ height: "32px", cursor: "pointer" }}
              src={burgerMenu}
              alt=""
            />
            <a
              style={{
                width: "auto",
                height: "auto",
                marginTop: "10px",
                marginLeft: "10px",
              }}
              href="/home"
            >
              <img
                style={{ height: "25px", width: "auto" ,marginBottom:"5px"}}
                className="navbar-icon"
                src={siteLogo}
                alt=""
              />
            </a>
          </div>

          <div className="flex align-center">
            <div className="coins-ui">
              <img src={coin} alt="" />
              <div style={{ whiteSpace: "nowrap", color: "white" }}>
                {coins}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="sidenav" ref={sideNavRef}>
        <div
          onClick={() => {
            sideNavRef.current.style.width = "0%";
            // sideNavRef.current.style.padding = "0px";
          }}
          style={{
            justifyContent: "flex-start",
            width: "100%",
            cursor: "pointer",
            fontSize: "var(--fs-600)",
            fontWeight: "700",
            marginTop: "20px",
            marginLeft: "30px",
          }}
          className="flex align-center "
        >
          <img
            src={arrowRight}
            style={{ width: "var(--fs-500)", marginRight: "7px" }}
            alt=""
          />
          Menu
        </div>
        <img
          style={{
            width: "90px",
            marginTop: "70px",
            height: "90px",
            borderRadius: "50%",
          }}
          src={loggedIn?.profilePic ? loggedIn.profilePic.toString() : user}
          alt=""
          referrerPolicy="no-referrer"
        />
        <div
          style={{
            fontSize: "var(--fs-600)",
            fontWeight: "bold",
            marginTop: "20px",
          }}
        >
          Welcome {loggedIn?.name ? loggedIn.name : ""}
        </div>

        <div
          style={{
            fontSize: "var(--fs-400)",
            marginTop: "5px",
          }}
        >
          Welcome To Biggest Quiz Hub
        </div>

        <div
          className="flex align-center"
          style={{ columnGap: "50px", marginTop: "12px" }}
        >
          {loggedIn ? (
            <button
              style={{
                border: "1px solid var(--color-border)",
                backgroundColor: "var(--color-button-background-color)",
                minWidth: "90px",
                cursor: "pointer",
              }}
              onClick={() => {
                logoutFromGoogle();
              }}
            >
              Logout
            </button>
          ) : (
            <>
              <button
                style={{
                  border: "1px solid var(--color-border)",
                  backgroundColor: "var(--color-button-background-color)",
                  minWidth: "90px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  authenticateWithGoogle();
                }}
              >
                Signup
              </button>
              <button
                style={{
                  border: "1px solid var(--color-border)",
                  backgroundColor: "var(--color-button-background-color)",
                  minWidth: "90px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  authenticateWithGoogle();
                }}
              >
                Login
              </button>
            </>
          )}
        </div>

        <div
          style={{ width: "100%", height: "1px", marginTop: "40px" }}
          className="seperation-line"
        ></div>

        <div
          style={{ width: "100%", columnGap: "12%", marginTop: "30px" }}
          className="flex align-center justify-center"
        >
          <div style={{ rowGap: "30px" }} className="flex column align-center ">
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/categories");
                closeSidebar();
              }}
              className="flex column align-center"
            >
              <img style={{ width: "45px" }} src={categoryIcon} alt="" />
              <div
                style={{
                  marginTop: "7px",
                  fontSize: "var(--fs-200)",
                  fontWeight: "bold",
                }}
              >
                Quiz Categories
              </div>
            </div>
            <div
              onClick={() => {
                navigate("/about");
                closeSidebar();
              }}
              style={{ cursor: "pointer" }}
              className="flex column align-center"
            >
              <img style={{ width: "45px" }} src={user2Icon} alt="" />
              <div
                style={{
                  marginTop: "7px",
                  fontSize: "var(--fs-200)",
                  fontWeight: "bold",
                }}
              >
                About Us
              </div>
            </div>
          </div>
          <div style={{ rowGap: "30px" }} className="flex column align-center ">
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/rules");
                closeSidebar();
              }}
              className="flex column align-center"
            >
              <img style={{ width: "45px" }} src={alertIcon} alt="" />
              <div
                style={{
                  marginTop: "7px",
                  fontSize: "var(--fs-200)",
                  fontWeight: "bold",
                }}
              >
                Rules For Players
              </div>
            </div>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/contact");
                closeSidebar();
              }}
              className="flex column align-center"
            >
              <img style={{ width: "45px" }} src={emailIcon} alt="" />
              <div
                style={{
                  marginTop: "7px",
                  fontSize: "var(--fs-200)",
                  fontWeight: "bold",
                }}
              >
                Contact Us
              </div>
            </div>
          </div>
          <div style={{ rowGap: "30px" }} className="flex column align-center ">
            <div
              onClick={() => {
                setOpenOverlay(true);
              }}
              style={{ cursor: "pointer" }}
              className="flex column align-center"
            >
              <img style={{ width: "45px" }} src={treasureIcon} alt="" />
              <div
                style={{
                  marginTop: "7px",
                  fontSize: "var(--fs-200)",
                  fontWeight: "bold",
                }}
              >
                Get 50 Coins Free
              </div>
            </div>
            <div
              onClick={() => {
                navigate("/privacy-policy");
                closeSidebar();
              }}
              style={{ cursor: "pointer" }}
              className="flex column align-center"
            >
              <img style={{ width: "45px" }} src={privacyIcon} alt="" />
              <div
                style={{
                  marginTop: "7px",
                  fontSize: "var(--fs-200)",
                  fontWeight: "bold",
                }}
              >
                Privacy Policy
              </div>
            </div>
          </div>
        </div>

        <div
          style={{ width: "100%", height: "1px", marginTop: "30px" }}
          className="seperation-line"
        ></div>
        <div
          style={{
            // position: "absolute",
            // bottom: "18%",
            // left: "25px",
            marginTop:"50px",
            maxWidth: "350px",
            textAlign: "center",
            lineHeight: "1.4",
          }}
          className="company-tag"
        >
          <img
            style={{ height: "30px", width: "auto" }}
            className="navbar-icon"
            src={siteLogo}
            alt=""
          />
          <div
            style={{
              fontSize: "var(--fs-300)",
              textAlign: "center",
              fontWeight: "700",
              marginTop: "7px",
            }}
          >
            World Popular Quiz Website, Serving Millions Of Player Around The
            World, Start Playing With Fun
          </div>
          <div
            style={{
              fontSize: "var(--fs-200)",
              textAlign: "center",
              fontWeight: "500",
              color: "var(--color-title-2)",
              marginTop: "7px",
            }}
          >
            SPDJ Office, Basariya, Chouparan,Hazaribagh, Jharkhand 825301 Email:
            spdjmedia@gmail.com SPDJ MEDIA PRIVATE LIMITED
          </div>
        </div>
      </div>
    </>
  );
}

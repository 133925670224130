import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import FirstQuestions from "./components/FirstQuestions";
import SidePoster from "./components/SidePoster";
import "./App.css";
import Home from "./components/Home";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import QuizInfoPage from "./components/QuizInfoPage";
import {  getCookie, setCookie } from "./utils";
import Profile from "./components/Profile";
import MainQuizPage from "./components/MainQuizPage";
import Categories from "./components/Categories";
import ContinueGame from "./components/ContinueGame";
import QuizEndPage from "./components/QuizEndPage";
import { initializeFirebase, IsLoggedIn } from "./firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import AboutUs from "./components/AboutUs";
import PrivacyPolicy from "./components/PrivacyPolicy";
import ContactUs from "./components/ContactUs";
import Rules from "./components/Rules";
import rightPaneImage from "./assets/bakenor-right-pane.png";
import webImage from "./assets/webImage.png";
import { checkAndSetCookie } from "./shortner_utils";
import { ShortDataHolder } from "./interface";

export const CoinsContext: any = React.createContext(null);
export const QuizPlayedContext: any = React.createContext(null);
export const LoginContext = React.createContext<any>(null);

export const ShortContext = React.createContext<any>(null);

function App() {
  const cookieCoins = parseInt(getCookie("quiz_coins")!);
  const defaultCoins = cookieCoins ? cookieCoins : 0;

  const cookiePlayed = parseInt(getCookie("quiz_played")!);
  const defaultPlayed = cookiePlayed ? cookiePlayed : 0;
  const [coins, setCoins] = useState(defaultCoins);
  const [playedQuiz, setPlayedQuiz] = useState(defaultPlayed);

  const [loggedIn, setLoggedIn] = useState<any>(null);

  const [isShort, setShort] = useState<ShortDataHolder|null>(null);

  useEffect(() => {
    async function checkShort() {
      const shortResult:any = await checkAndSetCookie()
      setShort(shortResult);
    }
    checkShort();
  }, []);

  useEffect(() => {
    initializeFirebase();
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setLoggedIn({
          name: user.displayName,
          email: user.email,
          profilePic: user.photoURL,
        });
      } else {
        setLoggedIn(null);
      }
    });
  }, []);

  useEffect(() => {
    setCookie("quiz_coins", coins.toString(), 60, "minutes");
  }, [coins]);

  useEffect(() => {
    setCookie("quiz_played", playedQuiz.toString(), 60, "minutes");
  }, [playedQuiz]);
  console.log("ran app");
  return (
    <ShortContext.Provider value={[isShort, setShort]}>
      <LoginContext.Provider value={[loggedIn, setLoggedIn]}>
        <CoinsContext.Provider value={[coins, setCoins]}>
          <QuizPlayedContext.Provider value={[playedQuiz, setPlayedQuiz]}>
            {console.log("ran app")}
            <div className="main-container">
              <div className="left-pane">
                <div className="react-body">
                  <Routes>
                    <Route path="/" element={<FirstQuestions />} />
                    <Route path="/continue-game" element={<ContinueGame />} />
                    <Route
                      path="/home"
                      element={[<NavBar />, <Home />, <Footer />]}
                    />
                    <Route
                      path="/profile"
                      element={[<NavBar />, <Profile />, <Footer />]}
                    />
                    <Route
                      path="/categories"
                      element={[<NavBar />, <Categories />, <Footer />]}
                    />
                    <Route
                      path="/info/:quizname"
                      element={[<NavBar />, <QuizInfoPage />, <Footer />]}
                    />

                    <Route
                      path="/play/:quizname"
                      element={[<NavBar />, <MainQuizPage />, <Footer />]}
                    />

                    <Route
                      path="/finished/:quizname"
                      element={[<NavBar />, <QuizEndPage />, <Footer />]}
                    />

                    <Route
                      path="/about"
                      element={[<NavBar />, <AboutUs />, <Footer />]}
                    />
                    <Route
                      path="/privacy-policy"
                      element={[<NavBar />, <PrivacyPolicy />, <Footer />]}
                    />
                    <Route
                      path="/contact"
                      element={[<NavBar />, <ContactUs />, <Footer />]}
                    />
                    <Route
                      path="/rules"
                      element={[<NavBar />, <Rules />, <Footer />]}
                    />
                  </Routes>
                </div>
              </div>
              <div className="right-pane">
                <img
                  style={{ maxWidth: "300px" }}
                  src={rightPaneImage}
                  alt=""
                />
                <div style={{ color: "#210E47", fontSize: "40px" }}>
                  Play Quiz{" "}
                  <span
                    style={{
                      fontSize: "40px",
                      color: "#210E47",
                      fontWeight: "700",
                    }}
                  >
                    & Win Coins
                  </span>
                </div>
                <div
                  style={{
                    color: "rgba(33, 14, 71, 0.74)",
                    marginTop: "10px",
                    fontSize: "var(--fs-100)",
                  }}
                >
                  Copyright © 2023 SPDJ MEDIA Pvt. Ltd. Gameus Quiz ® - All
                  rights reserved.
                </div>
                <div
                  style={{
                    position: "absolute",
                    bottom: "50px",
                    color: "#210E47",
                    fontSize: "var(--fs-700)",
                  }}
                >
                  For best experience, open{" "}
                  <span style={{ fontWeight: "700", color: "#210E47" }}>
                    gameus.io
                  </span>{" "}
                  on{" "}
                  <img
                    style={{
                      width: "var(--fs-600)",
                      verticalAlign: "middle",
                      marginBottom: "5px",
                    }}
                    src={webImage}
                    alt=""
                  />{" "}
                  Mobile Browser
                </div>
                {/* <SidePoster /> */}
              </div>
            </div>
          </QuizPlayedContext.Provider>
        </CoinsContext.Provider>
      </LoginContext.Provider>
    </ShortContext.Provider>
  );
}

export default App;

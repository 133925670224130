import React from "react";
import { useNavigate } from "react-router-dom";
import { QuizListItemInterface } from "../../interface";
import "./QuizListItem.css";
import coin from "../../assets/coin.png";
import { randombetween } from "../../utils";

export default function QuizListItem(props: QuizListItemInterface) {
  const navigate = useNavigate();
  return (
    <>
      {props ? (
        <div
          // onClick={() => {
          //   navigate("/info/" + props?.title, {
          //     state: { data: props },
          //   });
          // }}
          className="quiz-list-item"
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              transform: "translateY(15%)",
              position: "absolute",
              top: "0",
              left: "0",
            }}
            className="flex align-center"
          >
            <div className="horizontal-line"></div>
            <div className="vertical-line"></div>
          </div>

          <div // floating information textviews
            className="absolute-box-1"
          >
            <div className="thumbnail-container">
              <img
                style={{ width: "80%", height: "80%" }}
                src={`/` + props.image}
                alt=""
              />
            </div>
            <div
              className="flex column"
              style={{
                lineHeight: "1.5",
                alignContent: "flex-start",
                textAlign: "left",
              }}
            >
              <div
                className="font-300 "
                style={{ color: "var(--color-title-2)" }}
              >
                {props.title}
              </div>
              <div
                className="font-400 bold"
                style={{ color: "var(--color-title)" }}
              >
                Play and Win{" "}
                <img
                  style={{
                    verticalAlign: "middle",
                    height: "var(--fs-200)",
                    width: "var(--fs-400)",
                  }}
                  src={coin}
                  alt=""
                />{" "}
                {props.winning} coins
              </div>
              <div
                className="font-300 "
                style={{ color: "var(--color-title-2)" }}
              >
                Try you mind! Goodluck
              </div>
            </div>
          </div>
          <div className="absolute-box-2">
            <div className="live">• Live </div>
            <div className="horizontal-seperator"></div>
            <div className="playing">{randombetween(1200,3000)} Users Playing Now</div>
          </div>

          <div className="absolute-box-3">
            <button className="li-play-button">Play</button>

            <div className="quiz-entry-fee-tag">
              Entry Fee <img src={coin} alt="" /> 50
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

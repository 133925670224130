import React from "react";

export default function ContactUs() {
  return (
    <div style={{ marginTop: "40px", padding: "0 20px", lineHeight: "1.4" }}>
      <h2>Contact Us</h2>
      <p style={{ textAlign: "left" }}>
        Thank you for visiting Gameus Quiz! If you have any questions,
        comments, or concerns, please don't hesitate to reach out to us.
      </p>

      <p style={{ textAlign: "left" }}>
      You can contact us by email at: spdjmedia@gmail.com
      </p>

      <p style={{ textAlign: "left" }}>
      Our customer support team is available Monday through Friday from 9am to 5pm EST
      </p>

      <p style={{ textAlign: "left" }}>
      We value your feedback and strive to provide the best experience possible. Please let us know how we can improve and make your experience with Gameus Quiz even better.
      </p>

      <p style={{ textAlign: "left" }}>
      Thank you for choosing Gameus Quiz!
      </p>


    </div>
  );
}

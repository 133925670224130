import React, { useContext, useEffect, useState } from "react";
import InnerHTML from "dangerously-set-html-content"
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import "./QuizEndPage.css";
import coin from "../../assets/coin.png";
import trophyImage from "../../assets/trophy.png";
import confetti from "../../assets/confetti.png";
import useFreeIcon from "../../assets/usefree.png";
import QuizListItem from "../QuizListItem";
import { getResponse } from "../../utils";
import { CoinsContext, ShortContext } from "../../App";
import {
  getContinueTexts,
  openSharedisk,
  openSharediskDownload,
} from "../../shortner_utils";

export default function QuizEndPage() {
  //   const { state } = useLocation();
  function loadInterstitialAd() {
    window.googletag = window.googletag || { cmd: [] };
    let googletag = window.googletag;
    var interstitialSlot;
    googletag.cmd.push(function () {
      interstitialSlot = googletag.defineOutOfPageSlot(
        "/22815341665/Bakenor_Interstitial",
        googletag.enums.OutOfPageFormat.INTERSTITIAL
      );
      if (interstitialSlot) {
        interstitialSlot.addService(googletag.pubads());
      }
      googletag.pubads().set("page_url", "https://bakenor.com/");
      googletag.enableServices();
      googletag.display(interstitialSlot);
    });
  }

  const [recommendedQuizzes, setQuiz] = useState<any>(null);
  const [coins, setCoins] = useContext<any>(CoinsContext);
  const { state } = useLocation();
  const navigate = useNavigate();

  const [claimed, setClaimed] = useState<any>(false);

  useEffect(() => {
    async function connect() {
      const response = await getResponse("/all.json");
      setQuiz(response.quizzes);
    }
    connect();
  }, []);

  const [isShort, setShort] = useContext(ShortContext);
  const [instruction, setInstruction] = useState<any>(null);

  useEffect(() => {
    setInstruction(getContinueTexts("QuizEnd"));
  }, [isShort]);
  window.googletag = window.googletag || { cmd: [] };
  let googletag = window.googletag;
  function loadAdHead() {
    googletag.cmd.push(function () {
      var mapping1 = googletag
        .sizeMapping()
        .addSize(
          [479, 0],
          [
            [300, 250],
            [250, 250],
            [200, 200],
            [336, 280],
          ]
        )
        .addSize([0, 0], [[300, 250]])
        .build();
      googletag
        .defineSlot(
          "/22815341665/Bakenor_topbanner",
          [[300, 250]],
          "div-gpt-ad-6577565-3"
        )
        .defineSizeMapping(mapping1)
        .addService(googletag.pubads());
      googletag.pubads().enableSingleRequest();
      googletag.pubads().set("page_url", "https://bakenor.com/");
      googletag.enableServices();
    });
  }
  function loadAdBody() {
    googletag.cmd.push(function () {
      googletag.display("div-gpt-ad-6577565-3");
    });
  }
  useEffect(() => {
    try {
      console.log("loading banner");
      // loadAdHead();
      // loadAdBody();
      // setTimeout(() => {
      //   loadInterstitialAd();
      // }, 1000);
    } catch (error) {
      console.log(error);
    }
  }, []);
  return (
    <>
      <div
        style={{
          fontSize: "var(--fs-600)",
          color: "var(--color-title-2)",
          margin: "20px auto",
        }}
      >
        {state.title}
      </div>
      <div
        className="bold"
        style={{ color: "var(--color-title)", fontSize: "var(--fs-700)" }}
      >
        Play & Win{" "}
        <img
          style={{
            verticalAlign: "middle",
            height: "var(--fs-400)",
            width: "var(--fs-600)",
          }}
          src={coin}
          alt=""
        />{" "}
        {state.winning} coins
      </div>
      <div
        style={{
          fontSize: "var(--fs-600)",
          color: "var(--color-title-2)",
          margin: "7px auto",
        }}
      >
        Your Result!
      </div>
      <div
        style={{
          border: "1px solid var(--color-border)",
          padding: "1.5rem 1.5rem 2.5rem 1.5rem",
          margin: "0 10px",
          borderRadius: "15px",
        }}
      >
        <div style={{ fontSize: "var(--fs-700)", fontWeight: "700" }}>
          Well Played! Time is Over
        </div>
        <div
          style={{
            fontSize: "var(--fs-400)",
            fontWeight: "500",
            color: "var(--color-title-2)",
            marginTop: "6px",
          }}
        >
          You Did A Great Job
        </div>
        <div
          style={{
            fontSize: "var(--fs-400)",
            fontWeight: "500",
            color: "#FEC93E",
            marginTop: "10px",
          }}
        >
          Your Current Score is
        </div>

        <div
          style={{ width: "100%", marginTop: "30px", columnGap: "30px" }}
          className="flex align-center justify-center"
        >
          <img src={trophyImage} style={{ width: "80px" }} alt="" />
          <div style={{ fontSize: "var(--fs-800)", fontWeight: "700" }}>
            {state.score}
          </div>
          <img src={trophyImage} style={{ width: "80px" }} alt="" />
        </div>

        <div
          style={{
            fontSize: "var(--fs-400)",
            marginTop: "20px",
            justifyContent: "center",
            color: "var(--color-title-2)",
            display: "flex",
            alignItems: "center",
          }}
        >
          <img style={{ width: "var(--fs-500)" }} src={confetti} alt="" />{" "}
          Congratulations, You Just Won {state.score} coins{" "}
          <img
            style={{ width: "var(--fs-500)", height: "var(--fs-200)" }}
            src={coin}
            alt=""
          />
        </div>
      </div>

      <button
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "var(--purple-color)",
          width: "90%",
          margin: "0 auto",
          marginTop: "20px",
          borderBottom: "4px solid white",
          columnGap: "7px",
          paddingTop: "12px",
          paddingBottom: "12px",
        }}
        onClick={() => {
          if (!claimed) {
            setCoins((coinInput: any) => {
              return coinInput - 50;
            });

            setCoins((coinInput: any) => {
              return coinInput + state.score;
            });
            setClaimed(true);
          }
        }}
      >
        <img
          style={{ width: "var(--fs-800)", height: "var(--fs-800)" }}
          src={useFreeIcon}
          alt=""
        />
        Claim Your {state.score} Coins
      </button>
      <div
        style={{
          fontSize: "var(--fs-700)",
          fontWeight: "800",
          margin: "10px 0 ",
        }}
      >
        OR
      </div>

      <div
        onClick={() => {
          if (isShort) {
            if (state.gotoLink === "open sharedisk") {
              openSharedisk();
            } else if (state.gotoLink === "open sharedisk download") {
              openSharediskDownload();
            } else {
              window.location.href = state.gotoLink;
            }
          } else {
            window.location.href = "/home";
          }
        }}
        //  href={isShort? state.gotoLink:"/home"}
      >
        <button
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "transparent",
            margin: "0 auto",
            width: "90%",
            columnGap: "7px",
            border: "1px solid var(--color-border)",
            paddingTop: "15px",
            paddingBottom: "15px",
          }}
          // onClick={() => {
          //   navigate("/home");
          // }}
        >
          {isShort ? instruction : "Go To Home"}
        </button>
      </div>

      <div style={{ marginTop: "15px" }} className="live-adunit">
        <div id="div-gpt-ad-6577565-3"></div>
        <InnerHTML html={`
<script type="module" src="https://securepubads.shareusads.com/scripts/tag/js/sgpt.js" ></script>

<div id="shareusadx-3948125447778714-4323306444">
 <script>
     window._shareustag = window._shareustag || {cmd:[]};
    _shareustag.cmd.push(function() {
      var slot1 = _shareustag.defineSlot('/shareusadx/3948125447778714/4323306444/Main Adunit', [[300,250],[300,50],[300,75],[300,100]],'shareusadx-3948125447778714-4323306444');
    });
  </script>
</div>

`} />
      </div>

      <div
        style={{
          marginLeft: "20px",
          width: "fit-content",
          marginTop: "20px",
          marginBottom: "12px",
          fontSize: "var(--fs-600)",
          fontWeight: "700",
        }}
      >
        Play More Quizzes
      </div>
      {recommendedQuizzes ? (
        <div style={{ marginLeft: "20px" }} className="quiz-list">
          {recommendedQuizzes!.map((quiz: any, index: any) => {
            return <QuizListItem {...{ ...quiz }} />;
          })}
        </div>
      ) : (
        ""
      )}
    </>
  );
}

import axios from "axios";
import { isSignInWithEmailLink } from "firebase/auth";
import { b64EncodeUnicode, getCookie, sanitizeURL, setCookie } from "./utils";
export async function checkAndSetCookie() {
  // check which type of cookie and return
  const timeStampCookie = getCookie("__game_short"); // timestamp
  const typeCookie = getCookie("__game_short_type"); // 0,1,2
  const dataCookie = getCookie("__game_short_data"); // shortid
  if (!timeStampCookie) {
    console.log("no info cookie");
    return false;
  }
  try {
    const cookieTag = (await axios.get("/inf_data/game/c/" + timeStampCookie))
      .data;
    if (cookieTag.status === "ok") {
      console.log("token valid");
      return true;
    } else {
      console.log("token invalid");
      return false;
    }
  } catch (error) {
    return false;
  }
}

export function getContinueTexts(page: string) {
  const shortType = getCookie("__game_short_type");
  if (shortType === "0") {
    // Shareus Shortner
    if (page === "FirstQuestions") {
      return "Step 1/3";
    }
    if (page === "ContinueGame") {
      return "Step 2/3";
    }
    if (page === "Home") {
      return "Play Any 1 Quiz To Get Link";
    }
    if (page === "QuizInfo") {
      return "PLAY THIS QUIZ & GET LINK";
    }
    if (page === "MainQuiz") {
      return "Answer 5 questions to get link";
    }
    if (page === "QuizEnd") {
      return "GET LINK";
    }
  }

  if (shortType === "1") {
    // Sharedisk online
    if (page === "FirstQuestions") {
      return "Step 1/3";
    }
    if (page === "ContinueGame") {
      return "Step 2/3";
    }
    if (page === "Home") {
      return "Play Any 1 Quiz To Continue Watching";
    }
    if (page === "QuizInfo") {
      return "PLAY THIS QUIZ TO WATCH CONTINUE VIDEO";
    }
    if (page === "MainQuiz") {
      return "Answer 5 questions to continue watching";
    }
    if (page === "QuizEnd") {
      return "CONTINUE WATCHING VIDEO";
    }
  }

  if (shortType === "3") {
    // Sharedisk download
    if (page === "FirstQuestions") {
      return "Step 1/3";
    }
    if (page === "ContinueGame") {
      return "Step 2/3";
    }
    if (page === "Home") {
      return "Play Any 1 Quiz To Download Video";
    }
    if (page === "QuizInfo") {
      return "PLAY THIS QUIZ TO DOWNLOAD VIDEO";
    }
    if (page === "MainQuiz") {
      return "Answer 5 questions to download video";
    }
    if (page === "QuizEnd") {
      return "DOWNLOAD VIDEO";
    }
  }
}

export async function getFinalLink() {
  const shortType = getCookie("__game_short_type");
  if (shortType === "0") {
    const link = await (
      await axios.get(
        "https://us-central1-my-apps-server.cloudfunctions.net/r?shortid=" +
          getCookie("__game_short_data")
      )
    ).data;
    const finalLink =
      `https://go.shareus.in/?shortid=${getCookie("__game_short_data")}&link=` +
      b64EncodeUnicode(sanitizeURL(link));
    return finalLink;
  }
  if (shortType === "1") {
    return "open sharedisk";
  }

  if (shortType === "3") {
    return "open sharedisk download";
  }
}

export function openSharediskDownload(){
  const downloadData = getCookie("__game_short_data")
window.location.href = "https://shrdsk.me/download/"+  downloadData+ "?premium=false&quiz=true"
}

export function openSharedisk() {
  const shortid = getCookie("__game_short_data");
  const time = getCookie("__game_short_time");
  const form = document.createElement("form");
  form.setAttribute("id", "myDirectForm");
  form.setAttribute("method", "post");
  form.setAttribute(
    "action",
    `https://shrdsk.me/continue/${shortid}/${time}`
  );
  document.getElementsByTagName("body")[0].appendChild(form);

  const myForm: any = document.getElementById("myDirectForm");
  myForm.submit();
}

import React, { useEffect, useState } from "react";
import "./Categories.css";
import { CategoryTab, QuizListItemInterface } from "../../interface";
import { getResponse } from "../../utils";
import QuizListItem from "../QuizListItem";
import InnerHTML from "dangerously-set-html-content"

import searchIcon from "../../assets/searchIcon.png";
export default function Categories() {
  const [categories, setCategories] = useState<Array<CategoryTab> | null>(null); // changing only once
  const [quizzesList, setQuizzesList] =
    useState<Array<Array<QuizListItemInterface> | null> | null>(null); // changing only once
  const [currentTab, setCurrentTab]: any = useState(null); // index of active tab
  const [searchTerm, setSearchTerm]: any = useState("");

  useEffect(() => {
    const executeFunction = async () => {
      if (categories) {
        // fetching list of quizzes for each category
        if (!quizzesList) {
          const final_array: Array<Array<QuizListItemInterface>> = [];
          for (let i = 0; i < categories.length; i++) {
            const tab = categories[i];
            const urlpath = tab.filepath;
            const quizCategory = await getResponse(urlpath);
            if (quizCategory) {
              const quizzes = quizCategory.quizzes;
              const category = quizCategory.category;
              const quizList: Array<QuizListItemInterface> = [];
              for (let j = 0; j < quizzes.length; j++) {
                quizList.push({ ...quizzes[j] });
              }
              final_array.push(quizList);
            }
          }
          setQuizzesList(final_array);
        }
      }
    };
    executeFunction();
  }, [categories]); // runs when data is available

  useEffect(() => {
    // get categories list for populating initial UI
    const executeFunction = async () => {
      const data = await getResponse("/categories.json");
      if (data) {
        setCategories(data);
      }
    };
    executeFunction();
  }, []);
  useEffect(()=>{
  window.googletag = window.googletag || { cmd: [] };
  let googletag = window.googletag;
    function loadAdHead() {
      window.googletag = window.googletag || { cmd: [] };
      googletag.cmd.push(function () {
        var mapping1 = googletag
          .sizeMapping()
          .addSize(
            [479, 0],
            [
              [300, 250],
              [250, 250],
              [200, 200],
              [336, 280],
            ]
          )
          .addSize([0, 0], [[300, 250]])
          .build();
        googletag
          .defineSlot(
            "/22815341665/Bakenor_topbanner",
            [[300, 250]],
            "div-gpt-ad-6577565-3"
          )
          .defineSizeMapping(mapping1)
          .addService(googletag.pubads());
        googletag.pubads().enableSingleRequest();
        googletag.pubads().set("page_url", "https://bakenor.com/");
        googletag.enableServices();
      });
    }
    function loadAdBody() {
      googletag.cmd.push(function () {
        googletag.display("div-gpt-ad-6577565-3");
      });
    }

    // loadAdHead()
    // loadAdBody()
  })

  return (
    <div className="flex column align-center">
      <div style={{ marginTop: "10px" }} className="live-adunit">
        <div id="div-gpt-ad-6577565-3"></div>
        <InnerHTML html={`
<script type="module" src="https://securepubads.shareusads.com/scripts/tag/js/sgpt.js" ></script>

<div id="shareusadx-3948125447778714-4323306444">
 <script>
     window._shareustag = window._shareustag || {cmd:[]};
    _shareustag.cmd.push(function() {
      var slot1 = _shareustag.defineSlot('/shareusadx/3948125447778714/4323306444/Main Adunit', [[300,250],[300,50],[300,75],[300,100]],'shareusadx-3948125447778714-4323306444');
    });
  </script>
</div>

`} />

      </div>
      <div
        style={{
          fontSize: "var(--fs-600)",
          marginTop: "20px",
          fontWeight: "700",
        }}
      >
        {" "}
        Find Your Favourite Quiz{" "}
      </div>
      <div className="search-box">
        <img src={searchIcon} alt="" />
        <input
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
          type="input"
          placeholder="Search your favourite quiz"
        />
      </div>
      <div style={{ marginTop: "30px" }} className="grid-layout">
        {categories && currentTab === null
          ? categories.map((category, index: any) => {
            if(category.name.toLowerCase().indexOf(searchTerm.toLowerCase())>-1){
              return (
                <div
                  onClick={() => {
                    setCurrentTab(index);
                  }}
                  className="categories-button"
                >
                  {/* <img src={category.image} alt="" /> */}
                  <span>{category.name}</span>
                </div>
              );
            }
            })
          : ""}
      </div>

      {quizzesList?.[currentTab] ? (
        <div className="quiz-list">
          {quizzesList![currentTab]!.map((quiz, index) => {
            return <QuizListItem {...{ ...quiz }} />;
          })}
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

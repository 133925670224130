import React from "react";

export default function Rules() {
  return (
    <div style={{ marginTop: "40px", padding: "0 20px", lineHeight: "1.4" }}>
      <h2>Rules</h2>
      <p style={{ textAlign: "left" }}>
        In Gameus, winners for each quiz will be determined by the scores they
        earn during participation. Each quiz has a designated time for
        announcing the winners.
      </p>

      <p style={{ textAlign: "left" }}>
Participants will have 60 seconds to answer as many questions as possible out of 20 total questions. Four options will be provided for each question, and only one will be the correct answer.

      </p>

      <p style={{ textAlign: "left" }}>
Correct answers earn you 25 points, while wrong answers deduct 10 points.
      </p>

      <p style={{ textAlign: "left" }}>
Don't forget to utilize the lifelines provided, in case you get stuck during the contest. Each lifeline can only be used once per contest. You can either use coins from your coin bank or watch an ad for a few seconds to use the lifeline for free.
      </p>

      <p style={{ textAlign: "left" }}>
Gameus offers four different lifelines:
      </p>

      <p style={{ textAlign: "left" }}>
50:50: Eliminates two incorrect answers from the screen
Freezer Time: Pauses the timer for 30 seconds, providing more time to answer the question
Audience Poll: Allows you to choose the correct answer out of the four options, using the collective intelligence of other users.
Skip Question: Skips the current question so that you don't need to answer the question to continue the game.
      </p>

    </div>
  );
}

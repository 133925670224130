import React, { useContext, useState } from "react";
import treasureImage from "../../assets/treasure.png";
import coinsImage from "../../assets/coin.png";
import InnerHTML from "dangerously-set-html-content";
import confettiImage from "../../assets/confetti.png";
import "./ContinueGame.css";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import ShortStatusButton from "../ShortStatusButton";
import { ShortContext } from "../../App";
import { reportPage } from "../../utils";
import { getContinueTexts } from "../../shortner_utils";

export default function ContinueGame() {
  function loadAdHead() {
    window.googletag = window.googletag || { cmd: [] };
    let googletag = window.googletag;
    googletag.cmd.push(function () {
      var mapping1 = googletag
        .sizeMapping()
        .addSize(
          [479, 0],
          [
            [300, 250],
            [250, 250],
            [200, 200],
            [336, 280],
          ]
        )
        .addSize([0, 0], [[300, 250]])
        .build();
      googletag
        .defineSlot(
          "/22815341665/Bakenor_topbanner",
          [[300, 250]],
          "div-gpt-ad-6577565-2"
        )
        .defineSizeMapping(mapping1)
        .addService(googletag.pubads());
      googletag.pubads().enableSingleRequest();
      googletag.pubads().set("page_url", "https://bakenor.com/");
      googletag.enableServices();
    });
  }
  function loadAdBody() {
    let googletag = window.googletag;
    googletag.cmd.push(function () {
      googletag.display("div-gpt-ad-6577565-2");
    });
  }
  useEffect(() => {
    // loadAdHead();
    // loadAdBody();
    setTimeout(() => {
      // loadInterstitialAd();
    }, 1000);
  }, []);

  const [isShort, setShort] = useContext(ShortContext);
  const [instruction, setInstruction] = useState<any>(null);
  useEffect(() => {
    setInstruction(getContinueTexts("ContinueGame"));
  }, [isShort]);

  const navigate = useNavigate();
  return (
    <>
      <div className="flex column align-center">
        <img
          style={{ width: "127px", height: "127px" }}
          src={treasureImage}
          alt=""
        />

        <div
          style={{ marginTop: "30px", fontSize: "18px", fontWeight: "700" }}
          className=""
        >
          {" "}
          You've won{" "}
          <img
            style={{ width: "18px", verticalAlign: "middle" }}
            src={coinsImage}
            alt=""
          />{" "}
          200 Quiz coins!{" "}
          <img
            style={{ width: "22px", verticalAlign: "middle" }}
            src={confettiImage}
            alt=""
          />{" "}
        </div>

        <div
          style={{ width: "70%", margin: "20px auto 30px auto", height: "1px" }}
          className="seperation-line"
        ></div>

        <div style={{ marginTop: "0px", color: "var(--color-title-2)" }}>
          Start playing more quizzes and win more coins.
        </div>

        <a style={{ width: "100%" }} href="/home">
          <button
            onClick={() => {
              // navigate("/home");
            }}
            className="style-button-1 bold"
          >
            LET'S CONTINUE
          </button>
        </a>
        <div style={{ marginTop: "15px" }}>
          {isShort ? <ShortStatusButton {...{ step: instruction }} /> : ""}
        </div>
        <div style={{ marginTop: "20px" }} className="live-adunit">
          <div id="div-gpt-ad-6577565-2"></div>
          <InnerHTML
            html={`

<script type="module" src="https://securepubads.shareusads.com/scripts/tag/js/sgpt.js" ></script>
 <script>
     window._shareustag = window._shareustag || {cmd:[]};
    _shareustag.cmd.push(function() {
      var slot1 = _shareustag.defineOutOfPageSlot('/shareusadx/3948125447778714/9026652662/Interstitial');
    });
  </script>


            `}
          />
          <InnerHTML
            html={`
<script type="module" src="https://securepubads.shareusads.com/scripts/tag/js/sgpt.js" ></script>

<div id="shareusadx-3948125447778714-4323306444">
 <script>
     window._shareustag = window._shareustag || {cmd:[]};
    _shareustag.cmd.push(function() {
      var slot1 = _shareustag.defineSlot('/shareusadx/3948125447778714/4323306444/Main Adunit', [[300,250],[300,50],[300,75],[300,100]],'shareusadx-3948125447778714-4323306444');
    });
  </script>
</div>

`}
          />
        </div>

        <div className="quiz-instructions">
          <h4 style={{ margin: "30px 0 0 0 " }} className="font-500 ">
            Play Quiz and Win Coins!{" "}
          </h4>

          <ul style={{ textAlign: "left" }}>
            <li style={{ color: "var(--color-title-2)" }}>
              Welcome to Gameus, you ultimate destination for all things trivia
              and general knowledge.
            </li>
            <li style={{ color: "var(--color-title-2)" }}>
              Our website provides wide range of quizzes on a range of topics
              from history and science to pop culture and sports.
            </li>
            <li style={{ color: "var(--color-title-2)" }}>
              Our goal is to provide an enjoyable and educational experience for
              users of all age.
            </li>
            <li style={{ color: "var(--color-title-2)" }}>
              Our quizzes are constantly updated to ensure that you're always
              learning something new.
            </li>
            <li style={{ color: "var(--color-title-2)" }}>
              Our user-friendly interface makes it easy for you to find and take
              the quiz you're interested in.
            </li>
            <li style={{ color: "var(--color-title-2)" }}>
              Join the quizmaster community today and see how much you know!
            </li>
            <li style={{ color: "var(--color-title-2)" }}>
              Gameus: Quiz is trusted by millions of quiz community.
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

import axios from "axios";
import InnerHTML from 'dangerously-set-html-content';

import { useContext } from "react";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { CoinsContext, ShortContext } from "../../App";
import { getContinueTexts } from "../../shortner_utils";
import { getCookie, reportPage, setCookie, waitCall } from "../../utils";
import ShortStatusButton from "../ShortStatusButton";
import "./FirstQuestions.css";

function FirstQuestions() {
  function loadAdHead() {
    window.googletag = window.googletag || { cmd: [] };
    let googletag = window.googletag;
    googletag.cmd.push(function () {
      var mapping1 = googletag
        .sizeMapping()
        .addSize(
          [479, 0],
          [
            [300, 250],
            [250, 250],
            [200, 200],
            [336, 280],
          ]
        )
        .addSize([0, 0], [[300, 250]])
        .build();
      googletag
        .defineSlot(
          "/22815341665/Bakenor_topbanner",
          [[300, 250]],
          "div-gpt-ad-6577565-1"
        )
        .defineSizeMapping(mapping1)
        .addService(googletag.pubads());
      googletag.pubads().enableSingleRequest();
      googletag.pubads().set("page_url", "https://bakenor.com/");
      googletag.enableServices();
    });
  }
  function loadAdBody() {
    let googletag = window.googletag;
    googletag.cmd.push(function () {
      googletag.display("div-gpt-ad-6577565-1");
    });
  }
  useEffect(() => {
    // loadAdHead();
    // loadAdBody();
  }, []);

  const optionA: any = useRef(null);
  const optionB: any = useRef(null);
  const optionC: any = useRef(null);
  const optionD: any = useRef(null);

  const [coins, setCoins]: any = useContext(CoinsContext);

  const [question, setQuestion]: any = useState(null);
  const [questionIndex, setQuestionIndex]: any = useState(0);
  const [allQuestions, setAllQuestions]: any = useState();

  const [answerGiven, setAnswerGiven] = useState<boolean>(false);

  const [isShort, setShort] = useContext(ShortContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (getCookie("game_init")) {
      // navigate("/home");
    }
  }, []);

  useEffect(() => {
    async function getApi() {
      console.log("calling api");
      await new Promise((resolve) => {
        setTimeout(() => {
          resolve(null);
        }, 1000);
      });
      const myData = (await axios.get("/settings.json")).data;
      setQuestion(myData[questionIndex]);
      setAllQuestions(myData);
    }
    getApi();
  }, []);

  useEffect(() => {
    setAnswerGiven(false);
  }, [questionIndex]);

  async function setAnswer(selectedOption: string, optionRef: any) {
    if (answerGiven) {
      return;
    }
    setAnswerGiven(true);

    const currentQuestion = question;
    const correctOption = currentQuestion.correct;

    if (correctOption !== selectedOption) {
      optionRef.current.style.background = "var(--color-answer-wrong)";
      optionRef.current.classList.add("shake");
      await waitCall(1);
    }
    optionRef.current.classList.remove("shake");

    if (correctOption === "A") {
      optionA.current.style.background = "var(--color-answer-right)";
    }
    if (correctOption === "B") {
      optionB.current.style.background = "var(--color-answer-right)";
    }
    if (correctOption === "C") {
      optionC.current.style.background = "var(--color-answer-right)";
    }
    if (correctOption === "D") {
      optionD.current.style.background = "var(--color-answer-right)";
    }
    await waitCall(1);
    const array = [optionA, optionB, optionC, optionD];
    array.forEach((button) => {
      button.current.style.background = "";
    });

    if (questionIndex < allQuestions.length - 1) {
      setQuestion(allQuestions[questionIndex + 1]);
      setQuestionIndex(questionIndex + 1);
    } else {
      setCoins(150);
      setCookie("game_init", "true", 60, "minutes");
      setCookie("quiz_coins", "150", 60, "minutes");
      window.location.href = "/continue-game";
      // navigate("/continue-game");
    }
  }

  const [instruction, setInstruction] = useState<any>(null);
  useEffect(() => {
    setInstruction(getContinueTexts("FirstQuestions"));
    setTimeout(async () => {
      if (isShort) {
        await reportPage("f");
      }
    }, 2000);
  }, [isShort]);

  return (
    <div className="">
      <div style={{width:"100%",display:"flex", alignItems:"center",justifyContent:"center"}} className="live-adunit">
        {/* <div id="div-gpt-ad-6577565-1"></div> */}
        {/* <InnerHTML html={`

<script type="module" src="https://securepubads.shareusads.com/scripts/tag/js/sgpt.js" ></script>

<div id="shareusadx-3948125447778714-4323306444">
 <script>
     window._shareustag = window._shareustag || {cmd:[]};
    _shareustag.cmd.push(function() {
      var slot1 = _shareustag.defineSlot('/shareusadx/3948125447778714/4323306444/Main Adunit', [[300,250],[300,50],[300,75],[300,100]],'shareusadx-3948125447778714-4323306444');
    });
  </script>
</div>


          `} /> */}
        <InnerHTML html={`

<script
  async
  src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
></script>
<div id="gpt-passback">
  <script>
    window.googletag = window.googletag || { cmd: [] };
    googletag.cmd.push(function () {
      googletag
        .defineSlot(
          "/204286274/1001",
          [
            [300, 250],
            [300, 50],
            [300, 75],
            [300, 100],
          ],
          "gpt-passback"
        )
        .addService(googletag.pubads());
      googletag.enableServices();
      googletag.display("gpt-passback");
    });
  </script>
</div>


          `} />

      </div>
      <h4 style={{ margin: "10px 0" }} className="font-500">
        Let's begin
      </h4>
      <h6
        style={{ color: "var(--color-subtitle)", margin: "10px 0px" }}
        className="font-300"
      >
        Answer {allQuestions?.length} questions and win 150 free!
      </h6>
      {isShort ? <ShortStatusButton {...{ step: instruction }} /> : ""}
      <div
        style={{ width: "70%", margin: "20px auto 30px auto", height: "1px" }}
        className="seperation-line"
      ></div>

      {question ? (
        <div className="question-ui flex align-center column">
          <div style={{ color: "var(--color-title-2)" }} className="font-300">
            Question {questionIndex + 1}/{allQuestions.length}
          </div>
          <div
            style={{ margin: "10px 0px", color: "var(--color-title)" }}
            className="font-500 bold"
          >
            {question.question}
          </div>
          <div style={{ width: "100%" }} className="flex ">
            <button
              className="answer-button font-400"
              ref={optionA}
              onClick={() => setAnswer("A", optionA)}
            >
              {question.optionA}
            </button>
            <button
              className="answer-button font-400"
              ref={optionB}
              onClick={() => setAnswer("B", optionB)}
            >
              {question.optionB}
            </button>
          </div>
          <div style={{ width: "100%" }} className="flex ">
            <button
              className="answer-button font-400"
              ref={optionC}
              onClick={() => setAnswer("C", optionC)}
            >
              {question.optionC}
            </button>
            <button
              className="answer-button font-400"
              ref={optionD}
              onClick={() => setAnswer("D", optionD)}
            >
              {question.optionD}
            </button>
          </div>

          <div
            style={{
              width: "70%",
              margin: "0 auto",
              rowGap: "20px",
              marginTop: "80px",
            }}
            className="flex column "
          >
            <div style={{ height: "1px" }} className="seperation-line"></div>
            <div
              style={{
                fontWeight: "700",
                fontSize: "var(--fs-500)",
                color: "yellow",
              }}
            >
              Play Quiz And Win Coins.
            </div>

            {/* <Link to="/signup">
              <div style={{ color: "var(--color-title-2)" }}>
                {" "}
                Sign Up - Login
              </div>
            </Link> */}
            <div style={{ height: "1px" }} className="seperation-line"></div>
          </div>

          <div className="quiz-instructions">
            <h4 style={{ margin: "80px 0 0 0 " }} className="font-500 ">
              Play Quiz and Win Coins!{" "}
            </h4>

            <ul className="game-rules" style={{ textAlign: "left" }}>
              <li style={{ color: "var(--color-title-2)" }}>
                Welcome to Gameus, you ultimate destination for all things
                trivia and general knowledge.
              </li>
              <li style={{ color: "var(--color-title-2)" }}>
                Our website provides wide range of quizzes on a range of topics
                from history and science to pop culture and sports.
              </li>
              <li style={{ color: "var(--color-title-2)" }}>
                Our goal is to provide an enjoyable and educational experience
                for users of all age.
              </li>
              <li style={{ color: "var(--color-title-2)" }}>
                Our quizzes are constantly updated to ensure that you're always
                learning something new.
              </li>
              <li style={{ color: "var(--color-title-2)" }}>
                Our user-friendly interface makes it easy for you to find and
                take the quiz you're interested in.
              </li>
              <li style={{ color: "var(--color-title-2)" }}>
                Join the quizmaster community today and see how much you know!
              </li>
              <li style={{ color: "var(--color-title-2)" }}>
                Gameus: Quiz is trusted by millions of quiz community.
              </li>
            </ul>
          </div>
        </div>
      ) : (
        "Loading..."
      )}
    </div>
  );
}

export default FirstQuestions;

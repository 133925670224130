import React, { useEffect, useState, useRef } from "react";
import InnerHTML from "dangerously-set-html-content";
import { useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CoinsContext, LoginContext, ShortContext } from "../../App";
import { authenticateWithGoogle } from "../../firebase";
import { getCookie, getResponse, waitCall } from "../../utils";
import "./QuizInfoPage.css";
import coin from "../../assets/coin.png";
import arrowDown from "../../assets/arrow_down.png";
import { getContinueTexts } from "../../shortner_utils";

export default function QuizInfoPage() {
  // const { state } = useLocation();
  const navigate = useNavigate();
  const [allQuestions, setAllQuestions] = useState<any>(null);
  const [loggedIn, setLoggedIn]: any = useContext(LoginContext);
  const [state, setState] = useState<any>(null);

  const [prizesMenuOpen, setPrizesMenuOpen] = useState<boolean>(false);

  const [infoPageOpen, setInfoPageOpen] = useState<any>(false);
  useEffect(() => {
    // get all questions
    const downloadData = async () => {
      if (state) {
        const response = await getResponse(state.filepath);
        console.log(response);
        setAllQuestions(response.questions);
      }
    };
    downloadData();
  }, [state]);

  useEffect(() => {
    const cookieInfo = getCookie("__game_quizInfo");
    if (cookieInfo) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const index = urlParams.get("index");
      setState(JSON.parse(cookieInfo)[index ? index : 0]);
    } else {
      window.location.href = "/";
    }
  }, []);

  function startQuiz() {
    if (allQuestions) {
      navigate("/play/" + state.title, {
        state: {
          questions: allQuestions,
          title: state.title,
          image: state.image,
          winning: state.winning,
        },
      });
      // setCoins(coins-50)
    } else {
      // todo show loading
    }
  }
  const [isShort, setShort] = useContext(ShortContext);
  const [instruction, setInstruction] = useState<any>(null);
  useEffect(() => {
    setInstruction(getContinueTexts("QuizInfo"));
  }, [isShort]);
  window.googletag = window.googletag || { cmd: [] };
  let googletag = window.googletag;
  function loadAdHead() {
    window.googletag = window.googletag || { cmd: [] };
    googletag.cmd.push(function () {
      googletag
        .defineSlot(
          "/22815341665/Bakenor_responsive",
          [
            [300, 100],
            [300, 250],
          ],
          "div-gpt-ad-1675148914563-0"
        )
        .addService(googletag.pubads());
      googletag.pubads().set("page_url", "https://bakenor.com/");
      googletag.enableServices();
    });
  }
  function loadAdBody() {
    googletag.cmd.push(function () {
      googletag.display("div-gpt-ad-1675148914563-0");
    });
  }
  useEffect(() => {
    try {
      console.log("loading banner");
      // loadAdHead();
      // loadAdBody();
    } catch (error) {
      console.log(error);
    }
  }, []);
  return (
    <>
      {state ? (
        <>
          <div
            style={{
              border: "1px solid var(--color-border)",
              padding: "0px 20px",
              margin: "2px",
              marginTop: "50px",
              borderRadius: "10px",
              backgroundColor: "var(--color-button-background-color)",
            }}
            className={`flex column info-page-container ${
              infoPageOpen ? "open" : ""
            }`}
          >
            <div
              onClick={() => {
                setInfoPageOpen(!infoPageOpen);
              }}
              className="floating-view-more"
            >
              View More Detail <img src={arrowDown} alt="" />
            </div>
            <div // floating information textviews
              className="absolute-box-1 info-page"
            >
              <div className="thumbnail-container">
                <img
                  style={{ width: "80%", height: "80%" }}
                  src={`/` + state.image}
                  alt=""
                />
              </div>
              <div
                className="flex column"
                style={{
                  lineHeight: "1.5",
                  alignContent: "flex-start",
                  textAlign: "left",
                }}
              >
                <div
                  className="font-300 "
                  style={{ color: "var(--color-title-2)" }}
                >
                  {state.title}
                </div>
                <div
                  className="font-400 bold"
                  style={{ color: "var(--color-title)" }}
                >
                  Play and Win{" "}
                  <img
                    style={{
                      verticalAlign: "middle",
                      height: "var(--fs-200)",
                      width: "var(--fs-400)",
                    }}
                    src={coin}
                    alt=""
                  />{" "}
                  {state.winning} coins
                </div>
                <div
                  className="font-300 "
                  style={{ color: "var(--color-title-2)" }}
                >
                  Try you mind! Goodluck
                </div>
              </div>
            </div>

            <ul
              className="font-300"
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: "10px",
                textAlign: "left",
                fontWeight: "400",
                paddingInlineStart: "5%",
                lineHeight: "1.2",
              }}
            >
              <li>You've got 90 - 150 seconds to answer all questions.</li>
              <li>Answer as many questions as you can</li>
              <li>
                For every correct answer you will get +50 points and will loose
                -25 points on every incorrect answer
              </li>
              <div
                style={{
                  transition: "height 300ms ease ",
                  height: `${infoPageOpen ? "70px" : "0px"}`,
                  overflow: `${infoPageOpen ? "" : "hidden"}`,
                  // overflow:"hidden"
                }}
              >
                <li>
                  You can take help by using the lifelines present in the
                  contest.
                </li>
                <li>
                  Life lines can be used for free or by using a given amount of
                  coins for each lifeline.
                </li>
              </div>
            </ul>
          </div>

          <div style={{ margin: "30px auto" }} className="live-adunit ">
            <div
              id="div-gpt-ad-1675148914563-0"
              style={{
                minWidth: "100%",
                minHeight: "100px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <InnerHTML
                html={`
<script type="module" src="https://securepubads.shareusads.com/scripts/tag/js/sgpt.js" ></script>

<div id="shareusadx-3948125447778714-4323306444">
 <script>
     window._shareustag = window._shareustag || {cmd:[]};
    _shareustag.cmd.push(function() {
      var slot1 = _shareustag.defineSlot('/shareusadx/3948125447778714/4323306444/Main Adunit', [[300,250],[300,50],[300,75],[300,100]],'shareusadx-3948125447778714-4323306444');
    });
  </script>
</div>

`}
              />

              <script></script>
            </div>
          </div>

          <div
            style={{ marginTop: "20px", width: "100%", height: "2px" }}
            className="seperation-line"
          ></div>
          <div
            style={{
              width: "100%",
              columnGap: "30px",
              marginTop: "20px",
              fontSize: "var(--fs-600)",
            }}
            className="  bold flex align-center justify-center"
          >
            <div
              onClick={() => {
                setPrizesMenuOpen(!prizesMenuOpen);
              }}
              style={{ color: "yellow", cursor: "pointer" }}
            >
              See Prizes
            </div>

            <div
              style={{ height: "var(--fs-800)" }}
              className="horizontal-seperator"
            ></div>
            <Link to="/rules">
              <div style={{ color: "yellow" }}>Quiz Rules</div>
            </Link>
          </div>
          {prizesMenuOpen ? (
            <div className="rank-table" style={{ width: "100%" }}>
              <div className="square-invert"></div>
              <div style={{ fontSize: "var(--fs-400)" }} className="table-row">
                <span style={{ textAlign: "left" }}>Rank 1 </span>
                <span style={{ textAlign: "right" }}>
                  {" "}
                  <img
                    style={{ height: "var(--fs-200)" }}
                    src={coin}
                    alt=""
                  />{" "}
                  500
                </span>
              </div>
              <div style={{ fontSize: "var(--fs-400)" }} className="table-row">
                <span style={{ textAlign: "left" }}>Ranks: 2 - 5 </span>
                <span style={{ textAlign: "right" }}>
                  {" "}
                  <img
                    style={{ height: "var(--fs-200)" }}
                    src={coin}
                    alt=""
                  />{" "}
                  300
                </span>
              </div>
              <div style={{ fontSize: "var(--fs-400)" }} className="table-row">
                <span style={{ textAlign: "left" }}>Ranks: 6 - 10 </span>
                <span style={{ textAlign: "right" }}>
                  {" "}
                  <img
                    style={{ height: "var(--fs-200)" }}
                    src={coin}
                    alt=""
                  />{" "}
                  200
                </span>
              </div>
              <div style={{ fontSize: "var(--fs-400)" }} className="table-row">
                <span style={{ textAlign: "left" }}>Ranks: 11 - 20 </span>
                <span style={{ textAlign: "right" }}>
                  {" "}
                  <img
                    style={{ height: "var(--fs-200)" }}
                    src={coin}
                    alt=""
                  />{" "}
                  100
                </span>
              </div>
              <div style={{ fontSize: "var(--fs-400)" }} className="table-row">
                <span style={{ textAlign: "left" }}>Ranks: 21 - 30 </span>
                <span style={{ textAlign: "right" }}>
                  {" "}
                  <img
                    style={{ height: "var(--fs-200)" }}
                    src={coin}
                    alt=""
                  />{" "}
                  50
                </span>
              </div>
            </div>
          ) : (
            ""
          )}

          <div style={{ margin: "20px auto ", fontSize: "var(--fs-300)" }}>
            We're wishing you all the best for this quiz.
          </div>
          {loggedIn ? (
            ""
          ) : (
            <>
              <button
                onClick={startQuiz}
                style={{
                  fontSize: "var(--fs-400)",
                  backgroundColor: "var(--color-button-2-background-color)",
                  borderBottom: "4px solid white",
                }}
                className="play-button"
              >
                {isShort ? instruction : "PLAY AS GUEST"}
              </button>
              <div
                style={{
                  fontSize: "var(--fs-700)",
                  fontWeight: "700",
                  margin: "10px 0 ",
                }}
              >
                OR
              </div>
            </>
          )}
          <button
            onClick={() => {
              if (loggedIn) {
                startQuiz();
              } else {
                authenticateWithGoogle();
              }
            }}
            style={{
              fontSize: "var(--fs-500)",
              backgroundColor: "#FDE35E",
              borderBottom: "4px solid white",
              color: "black",
            }}
            className="play-button"
          >
            {isShort && loggedIn ? instruction : "Join & Play"}
          </button>
        </>
      ) : (
        ""
      )}
    </>
  );
}

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import { useContext } from "react";
import { LoginContext } from "../App";


const firebaseConfig = {
  apiKey: "AIzaSyDUb2_pMY_AKKNJENuHjtQXL3kA2mvWVcQ",
  authDomain: "referus-blog.firebaseapp.com",
  projectId: "referus-blog",
  storageBucket: "referus-blog.appspot.com",
  messagingSenderId: "80324772570",
  appId: "1:80324772570:web:86cf3b70b7ee42be4a1be3",
  measurementId: "G-61CPJW6H7P",
};

// Initialize Firebase
const provider = new GoogleAuthProvider();
// provider.addScope("https://www.googleapis.com/auth/contacts.readonly");

export function initializeFirebase(){
const app = initializeApp(firebaseConfig);
 const local_auth = getAuth();
}

export function authenticateWithGoogle() {
let auth = getAuth()
  signInWithPopup(auth, provider)
    .then((result) => {
      console.log(result);
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential?.accessToken;

      console.log(token);
      // The signed-in user info.
      const user = result.user;
      console.log(user);
    })
    .catch((error) => {
      console.log(error);
    });
}

export function logoutFromGoogle() {
    const auth = getAuth()
  signOut(auth);
}

export async function IsLoggedIn(): Promise<boolean> {
    const auth = getAuth()
  try {
    await new Promise((resolve, reject) =>
      onAuthStateChanged(
        auth,
        (user) => {
          if (user) {
            // User is signed in.
            resolve(user);
          } else {
            // No user is signed in.
            reject("no user logged in");
          }
        },
        // Prevent console error
        (error) => reject(error)
      )
    );
    return true;
  } catch (error) {
    return false;
  }
}
